import { createTheme } from "@material-ui/core"
import { Match } from "@reach/router"
import { Router } from "@reach/router"
import React from "react"
import { ThemeProvider } from "styled-components"
import Appbar from "../components/Appbar"
import Welcome from "../venue/login"
import Footer from "../components/Footer"
import Home from "../components/Home"
import Login from "../venue/login"
import About from "../venue/about"
import PressKit from "../venue/press-kit"
import Invest from "../venue/invest"
import Term from "../venue/terms-and-conditions"
import Privacy from "../venue/privacy-policy"
import Cookies from "../venue/cookies-policy"
import DownloadApp from "../venue/download-app"
import Help from "../venue/help"
import NotFound from "../components/NotFound"
import { useSelector } from "react-redux"
import MobileViewErrorUI from "../components/MobileViewErrorUI"

const theme = createTheme({
  "@global": {
    body: { backgroundColor: "white" },
  },
})

export default function HomePage() {
  const isActive = useSelector(state => state.bannerSlice.isActive)

  if (isActive) {
    return <MobileViewErrorUI />
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div style={{ background: "white" }}>
          <Appbar />
          <Router>
            <Home path="home" />
            <Match path="/venue/login">
              <Login />
            </Match>
            <Match path="/venue/welcome">
              <Welcome />
            </Match>
            <Match path="/sub-pages/about">
              <About />
            </Match>
            <Match path="/sub-pages/press">
              <PressKit />
            </Match>
            <Match path="/sub-pages/invest">
              <Invest />
            </Match>
            <Match path="/sub-pages/terms-and-conditions">
              <Term />
            </Match>
            <Match path="/sub-pages/privacy-policy">
              <Privacy />
            </Match>
            <Match path="/sub-pages/cookies-policy">
              <Cookies />
            </Match>
            <Match path="/sub-pages/download-app">
              <DownloadApp />
            </Match>

            <Match path="/sub-pages/help">
              <Help />
            </Match>
            <NotFound default />
          </Router>
          <Footer />
        </div>
      </ThemeProvider>
    </>
  )
}
